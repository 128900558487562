export const qcStatus = ['All', 'ToQC', 'QCed'];
export const committees = ['All', 'PRAC', 'PDCO', 'CHMP', 'CAT'];
export const years = [
  'All',
  '2024',
  '2023',
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012'
];

export const TEXT_FIELDS_MAPPING: any = {
  PRAC: [
    'section_number',
    'sub_section_number',
    'rapporteur',
    'co_rapporteur',
    'scope',
    'action',
    'background',
    'discussion'
  ],
  PDCO: [
    'section_number',
    'sub_section_number',
    'active_ingredient',
    'summary',
    'indication',
    'opinion_day',
    'pdco_number',
    'pdco_number_modified',
    'pip_number',
    'sponsor_name',
    'therapeutic_area'
  ],
  CHMP: [
    'section_number',
    'sub_section_number',
    'action_type',
    'active_ingredient',
    'sponsor_name',
    'brand_name',
    'co_rapporteur',
    'rapporteur',
    'indication',
    'prac_rapporteur',
    'product_number',
    'scope'
  ],
  CAT: [
    'section_number',
    'sub_section_number',
    'action_type',
    'active_ingredient',
    'brand_name',
    'sponsor_name',
    'rapporteur',
    'co_rapporteur',
    'prac_rapporteur',
    'indication',
    'product_number',
    'scope'
  ]
};
