import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid/DataGrid';

import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@mui/material/Dialog';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { Auth } from 'aws-amplify';

import styles from '../Datagrid/Datagrid.styles';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

import Styles from './styles/CategorizationTable.styles';

import themev5 from '../../themev5';

import Store from '../../store';
import Actions from '../../store/actions';
import {
  getApplicationDetails,
  getPediatricIndications,
  submitPediatricIndicationsAgeGroup
} from '../../api/Cder';
import PediatricIndicationAgeCategory from './PediatricIndicationAgeCategory';
import renderCellExpand from '../Datagrid/DatagridPopover';

const PediatricsIndicationTable = () => {
  const { dispatch } = useContext<any>(Store);
  const location = useLocation();
  const history = useHistory();

  const updatedSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const classes = Styles();

  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState('all');

  const [pageSize, setPageSize] = useState(10);
  const [indicationsData, setIndicationData] = useState<Array<any>>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState();
  const [loading, setLoading] = useState(true);

  const [openEdit, setOpenEidt] = useState(null);
  const [latestIndications, setShowOnlyLatestIndication] = useState(false);

  const rowsPerPageOptions = [10, 20, 50, 100, 200];

  const closeEdit = () => {
    setOpenEidt(null);
  };

  const handleEditClick = async (data: any) => {
    if (latestIndications) {
      setOpenEidt(data);
    } else {
      try {
        setLoading(true);
        const result = await getApplicationDetails(data?.id);
        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          const resultData = result.data.body.results;
          setOpenEidt({ ...data, ...resultData });
        } else {
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
        setOpenEidt(data);
      } finally {
        setLoading(false);
      }
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Identifier',
      width: 100,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'appl_details',
      headerName: 'Application Details',
      width: 250,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params: any) => {
        const applDetails = params?.row?.appl_details;
        return Array.isArray(applDetails) ? applDetails.join(', ') : applDetails;
      }
    },
    {
      field: 'indications',
      headerName: 'Indications',
      width: 450,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderCellExpand
    },
    {
      field: 'pediatrics_categories',
      headerName: 'Categories',
      width: 350,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Box display='flex' justifyContent='end' width='100%'>
            {params.row.pediatrics_categories && params.row.pediatrics_categories.length ? (
              params.row.pediatrics_categories.map((cat: string) => (
                <Chip label={cat} key={cat} sx={{ mr: 0.5, mt: 0.5 }} />
              ))
            ) : (
              <Chip label='None' />
            )}
          </Box>
        );
      }
    },
    {
      field: 'is_pediatrics',
      headerName: 'Is Pediatrics',
      width: 100,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'status',
      headerName: 'QC Status',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Actions',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Box
            style={{
              margin: '3px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Button
              variant='contained'
              onClick={() => {
                handleEditClick(params.row);
              }}>
              <Typography variant='body1'>Edit</Typography>
            </Button>
          </Box>
        );
      }
    }
  ];

  useEffect(() => {
    const curPage = updatedSearchParams.get('page') || '0';
    if (curPage !== pageNumber.toString()) {
      setPageNumber(parseInt(curPage, 10));
    }
    const curPageSize = updatedSearchParams.get('pageSize') || '10';
    if (curPageSize !== pageSize.toString()) {
      setPageSize(parseInt(curPageSize, 10));
    }
    const latestInd = updatedSearchParams.get('latestIndications') || 'false';
    setShowOnlyLatestIndication(latestInd === 'true');
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
  }, []);

  useEffect(() => {
    const fetchPediatricIndications = async () => {
      try {
        setLoading(true);
        const result = await getPediatricIndications(
          pageNumber,
          pageSize,
          status,
          searchText.trim().toLowerCase(),
          latestIndications
        );
        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.indications) {
          const resultData = result.data.body.indications;
          setTotalRowCount(result.data.body.total_count);
          setIndicationData(resultData);
        } else {
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      } finally {
        setLoading(false);
      }
    };
    fetchPediatricIndications();
  }, [pageNumber, status, pageSize, searchText, latestIndications]);

  const handleFormSubmit = async (formData: any) => {
    const user = await Auth.currentUserInfo();
    setLoading(true);
    setOpenEidt(null);
    const payload = {
      pediatrics_categories: formData.pediatrics_categories,
      status: formData.status,
      curator_email: user.attributes.email
    };
    try {
      const response = await submitPediatricIndicationsAgeGroup(formData.id, payload);
      if (response.data?.error) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: response.data.message }
        });
      } else if (response.data?.status === 200) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfuly updated the record', color: 'success' }
        });
        // Update the local record, this is instead of reloading the page
        const newIndicationData = [...indicationsData];
        const updatedIndex = newIndicationData.findIndex(data => data.id === formData.id);
        newIndicationData[updatedIndex] = {
          ...newIndicationData[updatedIndex],
          ...payload,
          is_pediatrics: formData.pediatrics_categories
            ? formData.pediatrics_categories.length > 0
            : !!formData.pediatrics_categories
        };
        setIndicationData(newIndicationData);
      } else {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Something went worng!' }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Something went worng!' }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Grid container>
        <Grid item xl={10} lg={10}>
          <Box display='flex' ml={2} mt={10} alignItems='center'>
            <TextField
              variant='outlined'
              type='search'
              id='search_indication'
              label='Search Indication'
              value={searchText}
              InputProps={{
                style: { fontSize: 16, minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: 16 }
              }}
              onChange={(e: any) => {
                setSearchText(e.target.value);
              }}
            />
            <TextField
              variant='outlined'
              id='status'
              label='QC Status'
              select
              value={status}
              onChange={e => setStatus(e.target.value)}
              InputProps={{
                style: { fontSize: 16, minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: 16 }
              }}>
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='Qced'>Qced</MenuItem>
              <MenuItem value='Escalate'>Escalate</MenuItem>
              <MenuItem value='not_qced'>Not Qced</MenuItem>
            </TextField>
          </Box>
        </Grid>
      </Grid>

      <Box display='flex' justifyContent='end'>
        <FormControlLabel
          control={
            <Switch
              checked={latestIndications}
              onChange={() => {
                setShowOnlyLatestIndication((prev: boolean) => !prev);
                updatedSearchParams.set('latestIndications', (!latestIndications).toString());
                history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
              }}
            />
          }
          sx={{ fontWeight: 'bold' }}
          label='Show Only Current Indications'
        />
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={indicationsData}
          columns={columns as any}
          rowCount={totalRowCount}
          pageSize={pageSize}
          getRowId={row => row.id}
          onPageSizeChange={(newPageSize: any) => {
            setPageSize(newPageSize);
            setPageNumber(0);
            updatedSearchParams.set('page', '0');
            updatedSearchParams.set('pageSize', newPageSize.toString());
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
          getRowHeight={() => 'auto'}
          onPageChange={(page: any) => {
            setPageNumber(page);
            updatedSearchParams.set('page', page.toString());
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNumber}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={!!openEdit}
          onClose={closeEdit}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          {openEdit ? (
            <PediatricIndicationAgeCategory
              closeEdit={closeEdit}
              onFormSubmit={handleFormSubmit}
              indicationData={openEdit}
            />
          ) : null}
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(PediatricsIndicationTable);
