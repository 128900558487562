import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import { LinearProgress } from '@mui/material';
import useMeetingData from './hooks/useMeetingData';
import PDFViewer from '../PDFViewer';
import SectionList from './Section/SectionList';

interface MeetingProps {
  match: {
    params: {
      committeeName: string;
      meetingId: string;
    };
  };
}

const Meeting: React.FC<MeetingProps> = ({ match }) => {
  const history = useHistory();
  const { meetingId } = match.params;
  const { committeeName } = match.params;
  const { getData, meetingData, onSave, onDelete, fetchApiData, loading } = useMeetingData();

  useEffect(() => {
    getData({
      committee_event_id: meetingId,
      committee_name: committeeName
    });
  }, [fetchApiData]);

  return (
    <Box mt={10} pl={2}>
      {loading ? (
        <LinearProgress sx={{ width: '100%', maxWidth: '600px' }} color='secondary' />
      ) : (
        <>
          <Box pb={2} pt={1}>
            <Stack
              direction='row'
              alignItems='center'
              onClick={() => history.goBack()}
              sx={{ cursor: 'pointer' }}>
              <KeyboardBackspaceIcon sx={{ fontSize: 32 }} />
              <Typography variant='h5'>Go Back</Typography>
            </Stack>
            <Typography variant='h6' color='black'>
              Sections Data
            </Typography>
          </Box>
          <Stack direction='row' sx={{ overflow: 'hidden', height: '85vh' }}>
            <Box flex={1} overflow='auto' pt={1}>
              <SectionList
                sections={meetingData?.sections}
                eventData={meetingData?.event_data}
                committeeName={committeeName}
                onSave={onSave}
                onDelete={onDelete}
              />
            </Box>

            <Box flex={1} pr={1}>
              <Box height='100%'>
                {meetingData?.event_data?.pdf_url && (
                  <PDFViewer fileUrl={meetingData?.event_data?.pdf_url} defaultScale={1} />
                )}
              </Box>
            </Box>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default React.memo(Meeting);
