import { useState, useCallback, useContext } from 'react';
import { Auth } from 'aws-amplify';
import Store from '../../../store';
import Actions from '../../../store/actions';
import {
  deleteEMACommitteesApplicationData,
  getEMACommitteesMeetingData,
  upsertEMACommitteesMeetingData
} from '../../../api/EmaCommittees';

const useMeetingData = () => {
  const { dispatch } = useContext<any>(Store);
  const [meetingData, setMeetingData] = useState<any>({});
  const [fetchApiData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const alert = useCallback((message: string, type: string) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message, color: type }
    });
  }, []);

  const handleError = (status: number) => {
    const message = status === 404 ? 'Could find not any meeting' : 'An error occurred';
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message }
    });
  };

  const getData = useCallback(async (payload: any) => {
    setLoading(true);
    try {
      const response = await getEMACommitteesMeetingData(payload);
      if (response.data.status === 200) {
        setMeetingData(response.data.body);
      } else {
        handleError(response.data.status);
      }
    } catch (e: any) {
      alert(e.message, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const onSave = async (data: any, committeeName: string) => {
    try {
      const user = await Auth.currentUserInfo();
      const payload = {
        committee_name: committeeName || '',
        data: [
          {
            ...data,
            curator_email: user.attributes.email,
            curator_name: user.attributes['custom:user']
          }
        ]
      };
      const response = await upsertEMACommitteesMeetingData(payload);
      if (response.data.status === 200) {
        alert('Section Data saved successfully', 'success');
      } else {
        handleError(response.data.status);
      }
    } catch (e: any) {
      alert(e.message, 'error');
    }
  };

  const onDelete = async (data: any, type: string, committeeName: string) => {
    try {
      const user = await Auth.currentUserInfo();
      const payload = {
        committee_name: committeeName || '',
        data: [
          {
            [type]: [
              {
                ...data,
                curator_email: user.attributes.email,
                curator_name: user.attributes['custom:user']
              }
            ]
          }
        ]
      };
      const response = await deleteEMACommitteesApplicationData(payload);
      if (response.data.status === 200) {
        alert(`${type} data deleted successfully`, 'success');
        return true;
      }
      handleError(response.data.status);
    } catch (e: any) {
      alert(e.message, 'error');
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return false;
    }
  };

  return {
    getData,
    meetingData,
    onSave,
    onDelete,
    fetchApiData,
    loading
  };
};

export default useMeetingData;
