import React, { useState } from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SectionItem from './SectionItem';
import { formatSectionKey } from '../utils';

const SectionItemWrapper: React.FC<any> = ({
  sectionData,
  committeeName,
  eventData,
  header,
  onSaveSectionData,
  onDelete
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const handleExpandToggle = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <Box sx={{ marginBottom: 2, border: '1px solid #ccc', padding: 2, borderRadius: 2 }}>
      <Accordion expanded={isExpanded} onChange={handleExpandToggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{formatSectionKey(header) || 'Untitled Section'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              setShowCreateForm(!showCreateForm);
            }}
            sx={{ marginTop: 1 }}>
            {showCreateForm ? 'Close' : 'Create New Sub-Section'}{' '}
          </Button>
          {showCreateForm && (
            <SectionItem
              sectionData={{
                ema_committee_event_pdf_id: eventData?.pdf_id
              }}
              committeeName={committeeName}
              onSave={onSaveSectionData}
              onDelete={onDelete}
              showCreateForm
            />
          )}
          {isExpanded &&
            sectionData?.map((data: any) => (
              <Box key={data.id}>
                <SectionItem
                  sectionData={data}
                  committeeName={committeeName}
                  onSave={onSaveSectionData}
                  onDelete={onDelete}
                />
              </Box>
            ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default React.memo(SectionItemWrapper);
