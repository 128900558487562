import React from 'react';
import { TextField, IconButton, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface EditableArrayFieldProps {
  label: string;
  values: string[];
  // eslint-disable-next-line no-unused-vars
  onChange: (values: string[]) => void;
}

const EditableArrayField: React.FC<EditableArrayFieldProps> = ({ label, values, onChange }) => {
  const handleAddField = () => {
    onChange([...values, '']);
  };

  const handleRemoveField = (index: number) => {
    const updatedValues = values.filter((_, i) => i !== index);
    onChange(updatedValues);
  };

  const handleFieldChange = (index: number, value: string) => {
    const updatedValues = [...values];
    updatedValues[index] = value;
    onChange(updatedValues);
  };

  return (
    <Box>
      <Typography gutterBottom paddingTop={1}>
        {label}
      </Typography>
      {values?.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} display='flex' alignItems='center' mb={0.25}>
          <TextField
            value={value}
            sx={{ minWidth: '90%' }}
            onChange={e => handleFieldChange(index, e.target.value)}
            multiline
            size='small'
            variant='outlined'
            label={label}
            margin='normal'
          />
          <IconButton onClick={() => handleRemoveField(index)} color='secondary'>
            <DeleteIcon />
          </IconButton>
        </Box>
      ))}
      <IconButton onClick={handleAddField} color='primary'>
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default React.memo(EditableArrayField);
