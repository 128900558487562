import React from 'react';
import { Box, TextField, Button, Typography, LinearProgress } from '@mui/material';
import useGPTData from '../hooks/useGPTData';

interface ApplicationDetailsProps {
  applicationDetails: Array<any>;
  pracEventPdfSectionContentId: string;
  // eslint-disable-next-line no-unused-vars
  onUpdate: (updatedDetails: Array<any>) => void;
  onDelete: any;
  applicationTitle: string;
  sponsorNameData: string;
  committeeName: string;
}

const ApplicationDetails: React.FC<ApplicationDetailsProps> = ({
  applicationDetails,
  pracEventPdfSectionContentId,
  applicationTitle,
  sponsorNameData,
  onUpdate,
  onDelete,
  committeeName
}) => {
  const { getData, loading } = useGPTData();

  const handleDetailChange = (index: number, field: string, value: string) => {
    const updatedDetails = [...applicationDetails];
    updatedDetails[index][field] = value;
    onUpdate(updatedDetails);
  };

  const addApplicationDetail = () => {
    onUpdate([
      ...applicationDetails,
      {
        prac_event_pdf_section_content_id: pracEventPdfSectionContentId,
        active_ingredient: '',
        authorization_partner: '',
        brand_name: '',
        sponsor_name: '',
        product_number: ''
      }
    ]);
  };

  const removeApplicationDetail = (index: number) => {
    const deleteItem = applicationDetails.find((_, i) => i === index);
    if (deleteItem && deleteItem.id) {
      onDelete(deleteItem, 'application_details', committeeName);
    }
    const updatedDetails = applicationDetails.filter((_, i) => i !== index);
    onUpdate(updatedDetails);
  };

  const onGPTCurationClick = async () => {
    const payload = {
      application_title: applicationTitle,
      sponsor_name_data: sponsorNameData,
      pdf_id: pracEventPdfSectionContentId,
      committee_name: 'PRAC'
    };

    const gptData = await getData(payload);
    const updatedDetails = [...applicationDetails, ...gptData];
    onUpdate(updatedDetails);
  };

  return (
    <Box>
      <Box paddingBottom={1}>
        <Typography alignSelf='center'>Application Details</Typography>
      </Box>
      <Box paddingBottom={2} display='flex' flexDirection='column'>
        <Typography alignSelf='center' textAlign='left' key='applicationTitle'>
          <b>Application Title:</b> {applicationTitle}
        </Typography>
        <Typography alignSelf='center' sx={{ textAlign: 'left', pt: 1, pb: 1 }} key='sponsorName'>
          <b>Sponsor Name:</b> {sponsorNameData}
        </Typography>
        <Button
          variant='outlined'
          onClick={onGPTCurationClick}
          sx={{ justifyContent: 'left', maxWidth: 'fit-content', mt: 1 }}>
          GPT Curation
        </Button>
      </Box>

      {loading ? (
        <LinearProgress sx={{ width: '100%', maxWidth: '600px' }} color='secondary' />
      ) : (
        <Box pt={1}>
          {applicationDetails?.map((detail, index) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{
                marginBottom: '1em',
                backgroundColor: detail?.gpt_extracted ? 'beige' : 'inherit',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <TextField
                label='Active Ingredient'
                value={detail.active_ingredient || ''}
                onChange={e => handleDetailChange(index, 'active_ingredient', e.target.value)}
                multiline
                size='small'
                margin='normal'
              />
              <TextField
                label='Brand Name'
                value={detail.brand_name || ''}
                onChange={e => handleDetailChange(index, 'brand_name', e.target.value)}
                multiline
                size='small'
                margin='normal'
              />
              <TextField
                label='Sponsor Name'
                value={detail.sponsor_name || ''}
                onChange={e => handleDetailChange(index, 'sponsor_name', e.target.value)}
                multiline
                size='small'
                margin='normal'
              />
              <TextField
                label='Authorization Type'
                value={detail.authorization_partner || ''}
                onChange={e => handleDetailChange(index, 'authorization_partner', e.target.value)}
                size='small'
                margin='normal'
              />
              <TextField
                label='Identification Number'
                value={detail.product_number || ''}
                onChange={e => handleDetailChange(index, 'product_number', e.target.value)}
                multiline
                size='small'
                margin='normal'
              />
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => removeApplicationDetail(index)}>
                Remove
              </Button>
            </Box>
          ))}
        </Box>
      )}
      <Button variant='outlined' color='primary' onClick={addApplicationDetail}>
        Add Application Detail
      </Button>
    </Box>
  );
};

export default React.memo(ApplicationDetails);
