import React from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

interface LinkDataProps {
  linkData: Array<any>;
  emaCommitteeEventPdfId: string;
  // eslint-disable-next-line no-unused-vars
  onUpdate: (updatedDetails: Array<any>) => void;
  onDelete: any;
  committeeName: string;
}

const LinkData: React.FC<LinkDataProps> = ({
  linkData,
  emaCommitteeEventPdfId,
  onUpdate,
  onDelete,
  committeeName
}) => {
  const handleDetailChange = (index: number, field: string, value: string) => {
    const updatedDetails = [...linkData];
    updatedDetails[index][field] = value;
    onUpdate(updatedDetails);
  };

  const addLinkData = () => {
    onUpdate([
      ...linkData,
      {
        ema_committee_event_pdf_id: emaCommitteeEventPdfId,
        link: '',
        link_text: '',
        page_number: '',
        section_number: ''
      }
    ]);
  };

  const removeLinkData = (index: number) => {
    const deleteItem = linkData.find((_, i) => i === index);
    if (deleteItem && deleteItem.id) {
      onDelete(deleteItem, 'link_data', committeeName);
    }
    const updatedDetails = linkData.filter((_, i) => i !== index);
    onUpdate(updatedDetails);
  };

  return (
    <Box>
      <Typography paddingTop={1} paddingBottom={1}>
        Link Data
      </Typography>
      {linkData?.map((detail, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} style={{ marginBottom: '1em' }}>
          <TextField
            label='Link'
            value={detail.link || ''}
            onChange={e => handleDetailChange(index, 'link', e.target.value)}
            fullWidth
            margin='normal'
          />
          <TextField
            label='Link Text'
            value={detail.link_text || ''}
            onChange={e => handleDetailChange(index, 'link_text', e.target.value)}
            fullWidth
            margin='normal'
          />
          <TextField
            label='Page Number'
            value={detail.page_number || ''}
            onChange={e => handleDetailChange(index, 'page_number', e.target.value)}
            fullWidth
            margin='normal'
          />
          <TextField
            label='Section Number'
            value={detail.section_number || ''}
            onChange={e => handleDetailChange(index, 'section_number', e.target.value)}
            fullWidth
            margin='normal'
          />
          <Button variant='outlined' color='secondary' onClick={() => removeLinkData(index)}>
            Remove
          </Button>
        </Box>
      ))}
      <Button variant='outlined' color='primary' onClick={addLinkData}>
        Add Link Data
      </Button>
    </Box>
  );
};

export default React.memo(LinkData);
