import React, { ChangeEvent, useContext, useState, useEffect, useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { debounce } from 'lodash';
import {
  Select,
  MenuItem,
  TextField,
  Box,
  Button,
  Typography,
  CssBaseline,
  Stack,
  Tooltip,
  Dialog,
  Link,
  Divider,
  LinearProgress,
  Switch,
  FormControlLabel,
  DialogTitle,
  IconButton,
  DialogActions,
  DialogContent
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Close from '@mui/icons-material/Close';
import themev5 from '../../../themev5';
import Store from '../../../store';
import Actions from '../../../store/actions';
import ProductNameForm from './ProductNameForm';
import {
  getAllApplications,
  getDagStatus,
  triggerPostCurationDagSteps
} from '../../../api/australia';

import useProductNameData from '../hooks/useProductNameData';

// Styles
import styles from '../styles/productNameCuration.styles';
import DatagridFooter from '../../../components/Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../../../components/Datagrid/DatagridNoRowsOverlay';

import ProductNameGridByAffix from './ProductNameGridByAffix';
import { statusOptions, logicOptions, artgWebsitePrefix } from '../constants';

const AustraliaProductNameCuration = () => {
  const [productData, setProductData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [datagridLoading, setDatagridLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [originalProductName, setOriginalProductName] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState('all');
  const [logicUsed, setLogicUsed] = useState('all');
  const [artgId, setArtgId] = useState<string | number>('');
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [vinNumberData, setVinNumberData] = useState({});
  const [reload, setReload] = useState(false);

  // dag states
  const [showCurationDialog, setShowCurationDialog] = useState<boolean>(false);
  const [curationStatus, setCurationStatus] = useState<any>({});
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [dagLoading, setDagLoading] = useState(false);

  const { dispatch } = useContext<any>(Store);

  const {
    trimType,
    trimValue,
    matchList,
    rowSelectionModel,
    setTrimType,
    setTrimValue,
    setMatchList,
    setRowSelectionModel,
    debouncedFetchData,
    handleTrimChange,
    datagridSelection,
    handleUpdate,
    dispatchAlert,
    loading,
    setLoading,
    toggleOriginalProductName,
    handleToggleChange
  } = useProductNameData();
  const rowsPerPageOptions = [10, 20, 50, 100];

  // Effect that triggers debounced API call when trimType or trimValue changes
  useEffect(() => {
    if (trimType && trimValue) {
      debouncedFetchData(trimType, trimValue);
    }
  }, [trimType, trimValue, debouncedFetchData]);

  // Apply changes based on the selection model, and update accordingly.
  const applyChanges = async () => {
    setLoading(true);
    // Filter matchList based on selectionModel of datagrid; include all if selectionModel is empty
    const filteredUpdates =
      rowSelectionModel.length > 0
        ? matchList.filter((item: any) => rowSelectionModel.includes(item.artg_id))
        : matchList;

    const updates = filteredUpdates.map((item: any) => {
      const productNameToUse = toggleOriginalProductName
        ? item.original_product_name
        : item.product_name;
      // Check if the product name matches the trim value exactly.
      if (productNameToUse.toLowerCase().trim() === trimValue.toLowerCase().trim()) {
        return {
          artg_id: item.artg_id,
          update_data: {
            product_name: productNameToUse.trim(), // Keep original name unchanged.
            status: 'qced'
          }
        };
      }
      // Calculate the new product name based on the type of trim required.
      const updatedProductName =
        trimType === 'prefix'
          ? productNameToUse.slice(0, trimValue.length)
          : productNameToUse.slice(0, productNameToUse.length - trimValue.length);
      return {
        artg_id: item.artg_id,
        update_data: {
          product_name: updatedProductName.trim(),
          status: 'qced'
        }
      };
    });
    await handleUpdate(updates);
    setReload(prevState => !prevState);
    setMatchList([]);
    setRowSelectionModel([]);
    setTrimValue('');
    setTrimType('');
    setLoading(false);
  };

  // Function to call API and fetch data based on filters
  const fetchApplications = useCallback(
    async (filters = {}) => {
      try {
        setDatagridLoading(true);
        const result = await getAllApplications(pageNo, pageSize, { filters });
        if (result?.data?.body?.applications) {
          setProductData(result.data.body.applications);
          setRowCount(result.data.body.total_count);
        } else {
          throw new Error('No data');
        }
        setDatagridLoading(false);
      } catch (error: any) {
        setDatagridLoading(false);
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: error.message || 'Error fetching applications' }
        });
      }
    },
    [pageNo, pageSize, dispatch, reload]
  );

  // Debounced fetch function to prevent excessive API calls
  const debouncedFetch = useCallback(
    debounce(filters => fetchApplications(filters), 500),
    [fetchApplications]
  );

  // Update filters and fetch data when any filter changes
  useEffect(() => {
    const filters: any = {};
    if (status !== 'all') filters.status = status;
    if (logicUsed !== 'all') filters.logic_used = logicUsed;
    if (artgId) filters.artg_id = artgId;
    if (originalProductName) filters.original_product_name = originalProductName;

    debouncedFetch(filters);
  }, [status, logicUsed, artgId, originalProductName, pageNo, pageSize, debouncedFetch]);

  // Open and close edit dialog
  const openEditDialog = (data: any) => {
    setVinNumberData(data);
    setShowEditDialog(true);
  };
  const closeEditDialog = () => {
    setShowEditDialog(false);
    setReload(prevState => !prevState);
  };

  const DATAGRID_COLUMN = [
    {
      field: 'Edit',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Button
          size='small'
          variant='contained'
          sx={styles.button}
          onClick={() => openEditDialog(params.row)}>
          <Typography variant='subtitle2'>Edit</Typography>
        </Button>
      )
    },
    {
      field: 'artg_id',
      headerName: 'ARTG ID',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Link href={`${artgWebsitePrefix}${params.row.artg_id}`} target='_blank'>
          <Typography variant='subtitle2'>{params.row.artg_id}</Typography>
        </Link>
      )
    },
    {
      field: 'original_product_name',
      headerName: 'Original Product Name',
      minWidth: 450,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Tooltip title={params.row.original_product_name}>
          <Typography variant='subtitle2'> {params.row.original_product_name}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'product_name',
      headerName: 'Extracted Product Name',
      width: 450,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Tooltip title={params.row.product_name}>
          <Typography variant='subtitle2'>{params.row.product_name}</Typography>
        </Tooltip>
      )
    },
    {
      field: 'logic_used',
      headerName: 'Extraction Logic Used',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    { field: 'status', headerName: 'QC Status', width: 350, headerAlign: 'center', align: 'center' }
  ];

  const bulkQcRows = async () => {
    if (rowSelectionModel.length === 0) {
      dispatchAlert('error', 'Please select rows to bulk QC');
      return;
    }
    setLoading(true);
    const filteredUpdates = productData.filter((item: any) =>
      rowSelectionModel.includes(item.artg_id)
    );

    const updates = filteredUpdates.map((item: any) => {
      return {
        artg_id: item.artg_id,
        update_data: {
          status: 'qced'
        }
      };
    });
    await handleUpdate(updates);
    setReload(prevState => !prevState);
    setMatchList([]);
    setRowSelectionModel([]);
    setTrimValue('');
    setTrimType('');
    setLoading(false);
  };

  const fetchCurationStatus = async () => {
    setDagLoading(true);
    try {
      const result = await getDagStatus();

      if (result === undefined) {
        throw new Error('Result is undefined');
      }
      if (result.data && result.data.body.results) {
        setCurationStatus(result.data.body.results);
        setDisableSubmit(
          Object.values(result.data.body.results)
            .map((obj: any) => obj.status.toLowerCase() === 'running')
            .includes(true)
        );
      } else {
        setDagLoading(false);
        throw Error('No data');
      }
    } catch (e: any) {
      dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
      setDagLoading(false);
    }
    setDagLoading(false);
    setShowCurationDialog(true);
  };

  const openCurationDialog = useCallback(() => {
    fetchCurationStatus();
  }, []);

  const handleClick = async () => {
    try {
      const result = await triggerPostCurationDagSteps();

      if (result.data.status === 200) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message, color: 'success' }
        });
      } else {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message, color: 'error' }
        });
      }
      setCurationStatus(false);
    } catch (exception) {
      dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: (exception as Error).message }
      });
      setShowCurationDialog(false);
      setCurationStatus(false);
    }
    setShowCurationDialog(false);
  };

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Stack
        direction='row'
        spacing={2}
        px={1}
        mt={10}
        alignItems='center'
        justifyContent='flex-start'>
        <Stack direction='row' spacing={2}>
          {/* Select for status */}
          <Select
            value={status}
            onChange={e => setStatus(e.target.value)}
            size='small'
            variant='outlined'
            sx={{ paddingRight: 2 }}>
            {statusOptions.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {/* Select for logic_used */}
          <Select
            value={logicUsed}
            onChange={e => setLogicUsed(e.target.value)}
            size='small'
            variant='outlined'>
            {logicOptions.map((option: any) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {/* Textfield for artg_id */}
          <TextField
            variant='outlined'
            type='number'
            size='small'
            label='ARTG ID'
            value={artgId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target; // Destructure value from e.target
              setArtgId(value === '' ? '' : parseInt(value, 10));
            }}
          />

          {/* Textfield for original product name */}
          <TextField
            variant='outlined'
            size='small'
            label='Original Product Name'
            value={originalProductName}
            onChange={e => setOriginalProductName(e.target.value)}
          />
        </Stack>
        <Divider orientation='vertical' flexItem />
        <Stack
          direction='row-reverse'
          alignItems='center'
          sx={{ borderRadius: 2, border: '1px solid gray', p: 1 }}>
          <Stack spacing={1} alignItems='flex-end'>
            <FormControlLabel
              control={
                <Switch
                  size='small'
                  checked={toggleOriginalProductName}
                  onChange={handleToggleChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={
                <Typography variant='subtitle2'>
                  {toggleOriginalProductName ? 'Original' : 'Extracted'} Product Name
                </Typography>
              }
            />
          </Stack>
          <Stack direction='row' spacing={2}>
            {/* Textfield for suffix */}
            <TextField
              label='Suffix to be removed:'
              variant='outlined'
              size='small'
              value={trimType === 'suffix' ? trimValue : ''}
              onChange={e => handleTrimChange(e, 'suffix')}
              disabled={trimType !== '' && trimType !== 'suffix'}
            />

            {/* Textfield for prefix */}
            <TextField
              label=' Prefix to be kept:'
              variant='outlined'
              size='small'
              value={trimType === 'prefix' ? trimValue : ''}
              onChange={e => handleTrimChange(e, 'prefix')}
              disabled={trimType !== '' && trimType !== 'prefix'}
            />
          </Stack>
        </Stack>
      </Stack>

      {/* components for affix match results */}
      <Stack p={1}>
        <ProductNameGridByAffix
          matchList={matchList}
          trimType={trimType}
          trimValue={trimValue}
          loading={loading}
          applyChanges={applyChanges}
          rowSelectionModel={rowSelectionModel}
          datagridSelection={datagridSelection}
          toggleOriginalProductName={toggleOriginalProductName}
          height='70vh'
        />
      </Stack>

      {/* DataGrid for product name curation */}
      {matchList.length === 0 && (
        <Stack spacing={1}>
          <Stack spacing={2} pr={3} direction='row' justifyContent='flex-end'>
            <LoadingButton
              size='small'
              variant='contained'
              onClick={bulkQcRows}
              loading={loading}
              sx={{ color: 'white.main', fontWeight: 'bold' }}
              startIcon={<SyncAltIcon />}
              // loadingIndicator='Updating...'
              loadingPosition='start'>
              Bulk QC
            </LoadingButton>
            <Button
              size='small'
              variant='contained'
              onClick={openCurationDialog}
              disabled={dagLoading}
              sx={{ color: 'white.main', fontWeight: 'bold' }}
              startIcon={<DoneAllIcon />}>
              Complete QC
            </Button>
            <Dialog
              disableEscapeKeyDown
              fullWidth
              maxWidth='xs'
              open={showCurationDialog}
              onClose={() => setShowCurationDialog(false)}>
              <DialogTitle id='alert-dialog-title'>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
                    Confirm Submit
                  </Typography>
                  <IconButton aria-label='close' onClick={() => setShowCurationDialog(false)}>
                    <Close />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
                  {disableSubmit
                    ? 'Currently, Dag is running. Try again sometime later '
                    : 'Are you sure product name curation is completed?'}
                </Typography>

                {Object.keys(curationStatus).length !== 0 && (
                  <Stack mt={2} flex='display' flexDirection='column'>
                    {Object.entries(curationStatus).map(([key, value]: any) => (
                      <Typography style={{ fontSize: '16px', marginRight: '0' }}>
                        {`${key}`} :{' '}
                        <span
                          style={{
                            color:
                              value &&
                              ['running', 'queued', 'up for retry'].includes(
                                value?.status.toLowerCase()
                              )
                                ? 'red'
                                : 'green'
                          }}>
                          {`${value.status}`}{' '}
                        </span>
                        <span>{`${value.last_scheduling_decision}`}</span>
                      </Typography>
                    ))}
                  </Stack>
                )}
              </DialogContent>
              <DialogActions>
                <Tooltip
                  title={
                    disableSubmit
                      ? 'Dag is already running/queued for refresh'
                      : 'Clck here to trigger the dag'
                  }>
                  <Button
                    variant='contained'
                    disabled={disableSubmit}
                    onClick={handleClick}
                    sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
                    Trigger Now
                  </Button>
                </Tooltip>
                <Button
                  variant='contained'
                  onClick={() => setShowCurationDialog(false)}
                  sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </Stack>
          <Box
            height='75vh'
            p={2}
            display='flex'
            alignItems='center'
            justifyContent='center'
            sx={styles.root}>
            <DataGrid
              rows={productData}
              columns={DATAGRID_COLUMN as any}
              rowCount={rowCount}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
              onPageChange={setPageNo}
              rowsPerPageOptions={rowsPerPageOptions}
              pagination
              loading={datagridLoading}
              getRowId={(row: any) => row.artg_id}
              paginationMode='server'
              components={{
                LoadingOverlay: LinearProgress,
                Footer: DatagridFooter,
                NoRowsOverlay: DatagridNoRowsOverlay
              }}
              checkboxSelection
              selectionModel={rowSelectionModel as any}
              onSelectionModelChange={(newRowSelectionModel: any) =>
                datagridSelection(newRowSelectionModel)
              }
            />
            <Dialog open={showEditDialog} onClose={closeEditDialog} maxWidth='xl' fullWidth>
              <ProductNameForm closeEdit={closeEditDialog} productData={vinNumberData} />
            </Dialog>
          </Box>
        </Stack>
      )}
    </ThemeProvider>
  );
};

export default React.memo(AustraliaProductNameCuration);
