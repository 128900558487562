import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApplicationDetails from './ApplicationDetails';
import EditableArrayField from './EditableArrayField';
import EditableTextField from './EditableTextField';
import LinkData from './LinkData';
import GenericDropdown from '../../RegulatoryInformation/components/GenericDropdown';
import { isHighPriority } from '../utils';
import { TEXT_FIELDS_MAPPING } from '../constants';

interface SectionItemProps {
  sectionData: any;
  committeeName: string;
  // eslint-disable-next-line no-unused-vars
  onSave: (data: any, committeeName: string) => void;
  onDelete: any;
  // eslint-disable-next-line react/require-default-props
  showCreateForm?: boolean;
}

const qcStatus = ['ToQC', 'QCed'];
const SectionItem: React.FC<SectionItemProps> = ({
  sectionData,
  committeeName,
  onSave,
  onDelete,
  showCreateForm = false
}) => {
  const [sectionDetails, setSectionDetails] = useState(sectionData);
  const [isExpanded, setIsExpanded] = useState(false);

  const textFields = TEXT_FIELDS_MAPPING[committeeName];

  const handleExpandToggle = () => {
    setIsExpanded(prev => !prev);
  };

  const handleFieldChange = (field: string, value: any) => {
    setSectionDetails((prev: any) => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    onSave(sectionDetails, committeeName);
  };

  useEffect(() => {
    if (showCreateForm) handleExpandToggle();
  }, [showCreateForm]);
  return (
    <Accordion expanded={isExpanded} onChange={handleExpandToggle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {!showCreateForm ? (
          <Typography>{`Sub Section ${sectionDetails.sub_section_number || 'Untitled'} (${
            sectionDetails.qc_status
          }) (Priority:${isHighPriority(sectionDetails) ? 'High' : 'Low'})`}</Typography>
        ) : (
          <Typography>Create New Sub-Section</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
        {isExpanded && (
          <>
            {textFields?.map((field: any) => (
              <EditableTextField
                label={field}
                key={field}
                value={sectionDetails[field]}
                onChange={(value: any) => handleFieldChange(field, value)}
              />
            ))}
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />

            {['PRAC'].includes(committeeName) && (
              <>
                <EditableArrayField
                  label='Summary'
                  values={sectionDetails.summary || []}
                  onChange={(newValues: any) => handleFieldChange('summary', newValues)}
                />
                <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
              </>
            )}

            {['CHMP', 'CAT'].includes(committeeName) && (
              <>
                <EditableArrayField
                  label='Tagged Words'
                  values={sectionDetails.tagged_words || []}
                  onChange={(newValues: any) => handleFieldChange('tagged_words', newValues)}
                />
                <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
              </>
            )}

            {['CHMP', 'CAT'].includes(committeeName) && (
              <>
                <EditableArrayField
                  label='Action Summary'
                  values={sectionDetails?.action_summary || []}
                  onChange={(newValues: any) => handleFieldChange('action_summary', newValues)}
                />
                <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
              </>
            )}

            {committeeName === 'PRAC' && (
              <>
                <EditableArrayField
                  label='Post Meeting Notes'
                  values={sectionDetails.post_meeting_notes || []}
                  onChange={(newValues: any) => handleFieldChange('post_meeting_notes', newValues)}
                />
                <Divider style={{ marginTop: '8px', marginBottom: '16px' }} />
                <ApplicationDetails
                  applicationDetails={sectionDetails.application_details}
                  onUpdate={updatedAppDetails =>
                    handleFieldChange('application_details', updatedAppDetails)
                  }
                  pracEventPdfSectionContentId={sectionDetails.id}
                  applicationTitle={sectionDetails.application_title}
                  sponsorNameData={sectionDetails.sponsor_name_data}
                  onDelete={onDelete}
                  committeeName={committeeName}
                />
                <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                <LinkData
                  linkData={sectionDetails.link_data}
                  onUpdate={updatedAppDetails => handleFieldChange('link_data', updatedAppDetails)}
                  emaCommitteeEventPdfId={sectionDetails.ema_committee_event_pdf_id}
                  onDelete={onDelete}
                  committeeName={committeeName}
                />
                <Divider style={{ marginTop: '8px', marginBottom: '16px' }} />
              </>
            )}

            <GenericDropdown
              label='QC Status'
              options={qcStatus}
              selectedValue={sectionDetails.qc_status}
              onChange={(value: any) => handleFieldChange('qc_status', value)}
            />
            <Button variant='contained' color='primary' onClick={handleSave} sx={{ marginTop: 1 }}>
              Save
            </Button>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(SectionItem);
