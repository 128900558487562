import React from 'react';
import { Box } from '@mui/material';
import SectionItemWrapper from './SectionItemWrapper';

interface SectionListProps {
  sections: Record<string, Array<any>>;
  eventData: any;
  committeeName: string;
  onSave: any;
  onDelete: any;
}

const SectionList: React.FC<SectionListProps> = ({
  sections,
  eventData,
  committeeName,
  onSave,
  onDelete
}) => {
  return (
    <Box>
      {Object.keys(sections || {})?.map((key: any) => {
        return (
          <SectionItemWrapper
            key={key}
            header={key}
            sectionData={sections[key]}
            eventData={eventData}
            committeeName={committeeName}
            onDelete={onDelete}
            onSaveSectionData={onSave}
          />
        );
      })}
    </Box>
  );
};

export default React.memo(SectionList);
